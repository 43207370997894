"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var TemplateStore_1 = require("./TemplateStore");

function getAssetConfig() {
  var useLowResSprites = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var resolutionPostFix = useLowResSprites ? '-small' : '';
  var animationScale = useLowResSprites ? 2 : 1;
  return {
    context: {
      interactive: true
    },
    tilemaps: [{
      key: 'Forest',
      jsonUrl: './assets/tilemaps/Forest_1/HomeMapHD_01.json',
      tilesets: [{
        name: 'HomeWorldHD_Tileset',
        image: './assets/tilemaps/Forest_1/HomeWorldHD_Tileset.png'
      }],
      layers: [{
        name: 'Background_001',
        tileset: 'HomeWorldHD_Tileset',
        animated: true
      }, {
        name: 'Background_002',
        tileset: 'HomeWorldHD_Tileset'
      }, {
        name: 'Midground_001',
        tileset: 'HomeWorldHD_Tileset'
      }, {
        name: 'Midground_002',
        tileset: 'HomeWorldHD_Tileset'
      }, {
        name: 'Foreground_001',
        tileset: 'HomeWorldHD_Tileset'
      }, {
        name: 'Decoration_001',
        tileset: 'HomeWorldHD_Tileset'
      }]
    }],
    sounds: [{
      key: 'thememusic',
      fileName: './assets/audio/music/thememusic-1.mp3',
      async: true
    }, {
      key: 'ambience',
      fileName: './assets/audio/sfx/OutdoorAmbienceLoop.mp3',
      async: true
    }, {
      key: 'player_footsteps_loop',
      fileName: './assets/audio/sfx/Footstep2.mp3'
    }, {
      key: 'floater-love',
      fileName: './assets/audio/sfx-creatures/floater-love.mp3'
    }, {
      key: 'emotion-friends',
      fileName: './assets/audio/sfx-creatures/emotion-friends.mp3'
    }, {
      key: 'feed-success',
      fileName: './assets/audio/sfx/FeedDragonSuccess.mp3'
    }, {
      key: 'feed-fail',
      fileName: './assets/audio/sfx/FeedDragonFail.mp3'
    }, {
      key: 'hit-wood',
      fileName: './assets/audio/sfx/ChopTree.mp3'
    }, {
      key: 'hit-crystals',
      fileName: './assets/audio/sfx/MineCrystal.mp3'
    }, {
      key: 'hit-stone',
      fileName: './assets/audio/sfx/MineBoulder.mp3'
    }, {
      key: 'hit-bush',
      fileName: './assets/audio/sfx/ForageBush2.mp3'
    }, {
      key: 'pick-wood',
      fileName: './assets/audio/sfx/TreeDropsLogs.mp3'
    }, {
      key: 'pick-berry',
      fileName: './assets/audio/sfx/BushDropsBerries.mp3'
    }, {
      key: 'pick-crystal',
      fileName: './assets/audio/sfx/CrystalDropsShards.mp3'
    }, {
      key: 'pick-stone',
      fileName: './assets/audio/sfx/BoulderDropsRock.mp3'
    }, {
      key: 'grab-general',
      fileName: './assets/audio/sfx/PickUpResource.mp3'
    }, {
      key: 'added-to-inventory',
      fileName: './assets/audio/sfx-objects/added-to-inventory.mp3'
    }, {
      key: 'click-registered',
      fileName: './assets/audio/sfx/ClickRegistered.mp3'
    }, {
      key: 'unlock-dragon',
      fileName: './assets/audio/sfx/UnlockDragon.mp3'
    }],
    templateStore: {
      atlases: [{
        name: 'home-world-atlas',
        image: './assets/tilemaps/Forest_1/HomeWorldHD_Atlas.png',
        json: './assets/tilemaps/Forest_1/HomeWorldHD_Atlas.json'
      }, {
        name: 'mouse-cursors',
        image: './assets/sprites/mouse-cursors.png',
        json: './assets/sprites/mouse-cursors.json'
      }, {
        name: 'tool-icons',
        image: './assets/sprites/tool-icons.png',
        json: './assets/sprites/tool-icons.json'
      }, {
        name: 'shadows',
        image: './assets/sprites/shadows.png',
        json: './assets/sprites/shadows.json'
      }, {
        name: 'resources-particles',
        image: './assets/sprites/resources-particles.png',
        json: './assets/sprites/resources-particles.json'
      }, {
        name: 'resources-icons',
        image: './assets/sprites/resources-icons.png',
        json: './assets/sprites/resources-icons.json'
      }, {
        name: 'harvestables',
        image: './assets/sprites/harvestables.png',
        json: './assets/sprites/harvestables.json'
      }, {
        name: 'nests',
        image: './assets/sprites/nests.png',
        json: './assets/sprites/nests.json'
      }, {
        name: 'hud',
        image: './assets/ui/hud.png',
        json: './assets/ui/hud.json'
      }, {
        name: 'dragon-spawner',
        image: './assets/sprites/dragon-spawner.png',
        json: './assets/sprites/dragon-spawner.json'
      }],
      spritesheets: [{
        name: 'player-animations',
        image: './assets/sprites/player-bold' + resolutionPostFix + '.png',
        json: './assets/sprites/player-bold' + resolutionPostFix + '.json',
        frameWidth: 160,
        frameHeight: 240,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'tool-S',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-W',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-E',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-N',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }]
      }, {
        name: 'player-tools-animations',
        image: './assets/sprites/player-tools-animations' + resolutionPostFix + '.png',
        json: './assets/sprites/player-tools-animations' + resolutionPostFix + '.json',
        frameWidth: 160,
        frameHeight: 240,
        animations: [{
          key: 'axe-S',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'axe-W',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'axe-E',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'axe-N',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'pickaxe-S',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'pickaxe-W',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'pickaxe-E',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'pickaxe-N',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }]
      }, {
        name: 'player-hair-animations',
        image: './assets/sprites/player-hair' + resolutionPostFix + '.png',
        json: './assets/sprites/player-hair' + resolutionPostFix + '.json',
        frameWidth: 138,
        frameHeight: 137,
        animations: [{
          key: 'walk-hair00-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair00-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair00-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair00-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'idle-hair00-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair00-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair00-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair00-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'tool-hair00-S',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair00-W',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair00-E',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair00-N',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'walk-hair01-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair01-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair01-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair01-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'idle-hair01-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair01-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair01-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair01-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'tool-hair01-S',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair01-W',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair01-E',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair01-N',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'walk-hair02-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair02-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair02-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair02-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'idle-hair02-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair02-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair02-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair02-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'tool-hair02-S',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair02-W',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair02-E',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair02-N',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'walk-hair03-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair03-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair03-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'walk-hair03-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10
        }, {
          key: 'idle-hair03-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair03-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair03-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'idle-hair03-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5
        }, {
          key: 'tool-hair03-S',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair03-W',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair03-E',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }, {
          key: 'tool-hair03-N',
          startFrame: 0,
          endFrame: 7,
          frameRate: 20,
          repeat: 0
        }]
      }, {
        name: 'dragon-a',
        image: './assets/sprites/dragon-a' + resolutionPostFix + '.png',
        json: './assets/sprites/dragon-a' + resolutionPostFix + '.json',
        async: true,
        frameWidth: 160,
        frameHeight: 240,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [195 / animationScale, 160 / animationScale, 252 / animationScale, 211 / animationScale]
          }
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [195 / animationScale, 130 / animationScale, 296 / animationScale, 215 / animationScale]
          }
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [195 / animationScale, 130 / animationScale, 296 / animationScale, 215 / animationScale]
          }
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [195 / animationScale, 150 / animationScale, 252 / animationScale, 217 / animationScale]
          }
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [195 / animationScale, 160 / animationScale, 252 / animationScale, 211 / animationScale]
          }
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [195 / animationScale, 130 / animationScale, 296 / animationScale, 215 / animationScale]
          }
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [195 / animationScale, 130 / animationScale, 296 / animationScale, 215 / animationScale]
          }
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [195 / animationScale, 150 / animationScale, 252 / animationScale, 217 / animationScale]
          }
        }, {
          key: 'sleep-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [195 / animationScale, 160 / animationScale, 294 / animationScale, 148 / animationScale]
          }
        }]
      }, {
        name: 'dragon-b',
        image: './assets/sprites/dragon-b' + resolutionPostFix + '.png',
        json: './assets/sprites/dragon-b' + resolutionPostFix + '.json',
        async: true,
        frameWidth: 160,
        frameHeight: 240,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [154 / animationScale, 124 / animationScale, 172 / animationScale, 129 / animationScale]
          }
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [154 / animationScale, 124 / animationScale, 121 / animationScale, 140 / animationScale]
          }
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [154 / animationScale, 124 / animationScale, 121 / animationScale, 140 / animationScale]
          }
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [154 / animationScale, 124 / animationScale, 162 / animationScale, 134 / animationScale]
          }
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [154 / animationScale, 124 / animationScale, 172 / animationScale, 129 / animationScale]
          }
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [154 / animationScale, 124 / animationScale, 121 / animationScale, 140 / animationScale]
          }
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [154 / animationScale, 124 / animationScale, 121 / animationScale, 140 / animationScale]
          }
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [154 / animationScale, 124 / animationScale, 162 / animationScale, 134 / animationScale]
          }
        }, {
          key: 'sleep-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [154 / animationScale, 124 / animationScale, 166 / animationScale, 94 / animationScale]
          }
        }]
      }, {
        name: 'dragon-c',
        image: './assets/sprites/dragon-c' + resolutionPostFix + '.png',
        json: './assets/sprites/dragon-c' + resolutionPostFix + '.json',
        async: true,
        frameWidth: 436,
        frameHeight: 350,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [218 / animationScale, 175 / animationScale, 249 / animationScale, 235 / animationScale]
          }
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [218 / animationScale, 175 / animationScale, 232 / animationScale, 244 / animationScale]
          }
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [218 / animationScale, 175 / animationScale, 232 / animationScale, 244 / animationScale]
          }
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [223 / animationScale, 175 / animationScale, 258 / animationScale, 235 / animationScale]
          }
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [218 / animationScale, 175 / animationScale, 249 / animationScale, 235 / animationScale]
          }
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [218 / animationScale, 175 / animationScale, 232 / animationScale, 244 / animationScale]
          }
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [218 / animationScale, 175 / animationScale, 232 / animationScale, 244 / animationScale]
          }
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [223 / animationScale, 175 / animationScale, 258 / animationScale, 235 / animationScale]
          }
        }, {
          key: 'sleep-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [170 / animationScale, 155 / animationScale, 249 / animationScale, 235 / animationScale]
          }
        }]
      }, {
        name: 'dragon-d',
        image: './assets/sprites/dragon-d' + resolutionPostFix + '.png',
        json: './assets/sprites/dragon-d' + resolutionPostFix + '.json',
        async: true,
        frameWidth: 436,
        frameHeight: 350,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [192 / animationScale, 174 / animationScale, 229 / animationScale, 211 / animationScale]
          }
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [182 / animationScale, 174 / animationScale, 206 / animationScale, 222 / animationScale]
          }
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [212 / animationScale, 174 / animationScale, 206 / animationScale, 222 / animationScale]
          }
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [197 / animationScale, 174 / animationScale, 214 / animationScale, 222 / animationScale]
          }
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [192 / animationScale, 174 / animationScale, 229 / animationScale, 211 / animationScale]
          }
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [182 / animationScale, 174 / animationScale, 206 / animationScale, 222 / animationScale]
          }
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [212 / animationScale, 174 / animationScale, 206 / animationScale, 222 / animationScale]
          }
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [197 / animationScale, 174 / animationScale, 214 / animationScale, 222 / animationScale]
          }
        }, {
          key: 'sleep-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [197 / animationScale, 174 / animationScale, 228 / animationScale, 199 / animationScale]
          }
        }]
      }, {
        name: 'dragon-e',
        image: './assets/sprites/dragon-e' + resolutionPostFix + '.png',
        json: './assets/sprites/dragon-e' + resolutionPostFix + '.json',
        async: true,
        frameWidth: 436,
        frameHeight: 350,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [152 / animationScale, 135 / animationScale, 158 / animationScale, 191 / animationScale]
          }
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [152 / animationScale, 135 / animationScale, 166 / animationScale, 172 / animationScale]
          }
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [152 / animationScale, 135 / animationScale, 166 / animationScale, 172 / animationScale]
          }
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [152 / animationScale, 135 / animationScale, 167 / animationScale, 199 / animationScale]
          }
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [152 / animationScale, 135 / animationScale, 154 / animationScale, 199 / animationScale]
          }
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [152 / animationScale, 135 / animationScale, 166 / animationScale, 172 / animationScale]
          }
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [152 / animationScale, 135 / animationScale, 166 / animationScale, 172 / animationScale]
          }
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 3,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [152 / animationScale, 135 / animationScale, 167 / animationScale, 199 / animationScale]
          }
        }, {
          key: 'sleep-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 5,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [165 / animationScale, 185 / animationScale, 150 / animationScale, 96 / animationScale]
          }
        }]
      }, {
        name: 'winddragon',
        image: './assets/sprites/winddragon' + resolutionPostFix + '.png',
        json: './assets/sprites/winddragon' + resolutionPostFix + '.json',
        async: true,
        frameWidth: 450,
        frameHeight: 450,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 2,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [337 / animationScale, 337 / animationScale, 592 / animationScale, 337 / animationScale]
          }
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 2,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [337 / animationScale, 317 / animationScale, 413 / animationScale, 345 / animationScale]
          }
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 2,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [337 / animationScale, 317 / animationScale, 413 / animationScale, 345 / animationScale]
          }
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 2,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [337 / animationScale, 337 / animationScale, 615 / animationScale, 347 / animationScale]
          }
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 4,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [337 / animationScale, 280 / animationScale, 592 / animationScale, 424 / animationScale]
          }
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 4,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [337 / animationScale, 300 / animationScale, 425 / animationScale, 4111 / animationScale]
          }
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 4,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [337 / animationScale, 300 / animationScale, 425 / animationScale, 411 / animationScale]
          }
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 4,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [337 / animationScale, 280 / animationScale, 615 / animationScale, 436 / animationScale]
          }
        }, {
          key: 'sleep-E',
          startFrame: 0,
          endFrame: 4,
          frameRate: 4,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [337 / animationScale, 496 / animationScale, 431 / animationScale, 159 / animationScale]
          }
        }]
      }, {
        name: 'rockdragon',
        image: './assets/sprites/rockdragon' + resolutionPostFix + '.png',
        json: './assets/sprites/rockdragon' + resolutionPostFix + '.json',
        async: true,
        frameWidth: 450,
        frameHeight: 450,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 7,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [270 / animationScale, 280 / animationScale, 280 / animationScale, 318 / animationScale]
          }
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 4,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [270 / animationScale, 280 / animationScale, 368 / animationScale, 270 / animationScale]
          }
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 4,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [270 / animationScale, 280 / animationScale, 368 / animationScale, 270 / animationScale]
          }
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 7,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [270 / animationScale, 285 / animationScale, 262 / animationScale, 273 / animationScale]
          }
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 9,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [270 / animationScale, 280 / animationScale, 280 / animationScale, 318 / animationScale]
          }
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 4,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [270 / animationScale, 280 / animationScale, 368 / animationScale, 270 / animationScale]
          }
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 4,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [270 / animationScale, 280 / animationScale, 368 / animationScale, 270 / animationScale]
          }
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 9,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [270 / animationScale, 285 / animationScale, 262 / animationScale, 273 / animationScale]
          }
        }, {
          key: 'sleep-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [270 / animationScale, 330 / animationScale, 351 / animationScale, 239 / animationScale]
          }
        }]
      }, {
        name: 'firedragon',
        image: './assets/sprites/firedragon' + resolutionPostFix + '.png',
        json: './assets/sprites/firedragon' + resolutionPostFix + '.json',
        async: true,
        frameWidth: 450,
        frameHeight: 450,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [348 / animationScale, 337 / animationScale, 220 / animationScale, 415 / animationScale]
          }
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [348 / animationScale, 337 / animationScale, 503 / animationScale, 387 / animationScale]
          }
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [348 / animationScale, 337 / animationScale, 503 / animationScale, 387 / animationScale]
          }
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [348 / animationScale, 337 / animationScale, 273 / animationScale, 419 / animationScale]
          }
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [348 / animationScale, 337 / animationScale, 220 / animationScale, 415 / animationScale]
          }
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [348 / animationScale, 337 / animationScale, 503 / animationScale, 387 / animationScale]
          }
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [348 / animationScale, 337 / animationScale, 503 / animationScale, 387 / animationScale]
          }
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [348 / animationScale, 337 / animationScale, 273 / animationScale, 419 / animationScale]
          }
        }, {
          key: 'sleep-E',
          startFrame: 0,
          endFrame: 5,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [342 / animationScale, 337 / animationScale, 225 / animationScale, 387 / animationScale]
          }
        }]
      }, {
        name: 'serpertinedragon',
        image: './assets/sprites/serpertinedragon' + resolutionPostFix + '.png',
        json: './assets/sprites/serpertinedragon' + resolutionPostFix + '.json',
        async: true,
        frameWidth: 450,
        frameHeight: 450,
        animations: [{
          key: 'walk-S',
          startFrame: 0,
          endFrame: 11,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [351 / animationScale, 351 / animationScale, 361 / animationScale, 532 / animationScale]
          }
        }, {
          key: 'walk-W',
          startFrame: 0,
          endFrame: 11,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [351 / animationScale, 351 / animationScale, 551 / animationScale, 553 / animationScale]
          }
        }, {
          key: 'walk-E',
          startFrame: 0,
          endFrame: 11,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [351 / animationScale, 351 / animationScale, 551 / animationScale, 553 / animationScale]
          }
        }, {
          key: 'walk-N',
          startFrame: 0,
          endFrame: 11,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [336 / animationScale, 351 / animationScale, 264 / animationScale, 599 / animationScale]
          }
        }, {
          key: 'idle-S',
          startFrame: 0,
          endFrame: 11,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [351 / animationScale, 351 / animationScale, 361 / animationScale, 532 / animationScale]
          }
        }, {
          key: 'idle-W',
          startFrame: 0,
          endFrame: 11,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [351 / animationScale, 351 / animationScale, 551 / animationScale, 553 / animationScale]
          }
        }, {
          key: 'idle-E',
          startFrame: 0,
          endFrame: 11,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [351 / animationScale, 351 / animationScale, 551 / animationScale, 553 / animationScale]
          }
        }, {
          key: 'idle-N',
          startFrame: 0,
          endFrame: 11,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [336 / animationScale, 351 / animationScale, 264 / animationScale, 599 / animationScale]
          }
        }, {
          key: 'sleep-E',
          startFrame: 0,
          endFrame: 11,
          frameRate: 10,
          spriteShape: {
            type: TemplateStore_1.SpriteShapeType.ellipse,
            points: [351 / animationScale, 425 / animationScale, 367 / animationScale, 332 / animationScale]
          }
        }]
      }, {
        name: 'dragon-profiles',
        image: './assets/ui/dragonDex/dragon-profiles.png',
        json: './assets/ui/dragonDex/dragon-profiles.json',
        frameWidth: 450,
        frameHeight: 450,
        animations: []
      }],
      templates: [{
        name: 'player',
        image: 'player-animations',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'player-tools',
        image: 'player-tools-animations',
        scale: animationScale
      }, {
        name: 'player-hair',
        image: 'player-hair-animations',
        scale: animationScale
      }, // Creatures
      {
        name: 'dragon-a',
        image: 'dragon-a',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'dragon-b',
        image: 'dragon-b',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'dragon-c',
        image: 'dragon-c',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'dragon-d',
        image: 'dragon-d',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'dragon-e',
        image: 'dragon-e',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'winddragon',
        image: 'winddragon',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'rockdragon',
        image: 'rockdragon',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'firedragon',
        image: 'firedragon',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'serpertinedragon',
        image: 'serpertinedragon',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, // Creatures Icons
      {
        name: 'dragon-a-icon',
        image: 'dragon-profiles',
        frame: 'dragon-a',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'dragon-b-icon',
        image: 'dragon-profiles',
        frame: 'dragon-b',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'dragon-c-icon',
        image: 'dragon-profiles',
        frame: 'dragon-c',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'dragon-d-icon',
        image: 'dragon-profiles',
        frame: 'dragon-d',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'dragon-e-icon',
        image: 'dragon-profiles',
        frame: 'dragon-e',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'winddragon-icon',
        image: 'dragon-profiles',
        frame: 'winddragon',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'rockdragon-icon',
        image: 'dragon-profiles',
        frame: 'rockdragon',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'firedragon-icon',
        image: 'dragon-profiles',
        frame: 'firedragon',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, {
        name: 'serpertinedragon-icon',
        image: 'dragon-profiles',
        frame: 'serpertinedragon',
        gridSize: {
          width: 1,
          height: 1
        },
        scale: animationScale
      }, // Props
      {
        name: 'dragon-spawner-point',
        image: 'dragon-spawner',
        frame: 'spawn-point',
        gridSize: {
          width: 1,
          height: 1
        },
        shape: []
      }, {
        name: 'dragon-spawner-ring',
        image: 'dragon-spawner',
        frame: 'spawn-ring',
        gridSize: {
          width: 1,
          height: 1
        },
        shape: []
      }, {
        name: 'tree-stump-1',
        image: 'home-world-atlas',
        gridSize: {
          width: 2,
          height: 2
        }
      }, {
        name: 'tree-stump-2',
        image: 'home-world-atlas',
        gridSize: {
          width: 2,
          height: 2
        }
      }, {
        name: 'shadow-big',
        image: 'shadows',
        gridSize: {
          width: 1,
          height: 1
        },
        shape: []
      }, {
        name: 'dragon-nest',
        image: 'nests',
        frame: 'dragon-nest',
        gridSize: {
          width: 1,
          height: 1
        },
        shape: []
      }, {
        name: 'stone-circle',
        image: 'home-world-atlas',
        gridSize: {
          width: 2,
          height: 1
        },
        shape: ['..']
      }, // HARVASTABLES
      {
        name: 'crystal-blue-full',
        image: 'harvestables',
        frame: 'crystal-blue-full',
        gridSize: {
          width: 2,
          height: 1
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [75, 85, 160, 168]
        }
      }, {
        name: 'crystal-red-full',
        image: 'harvestables',
        frame: 'crystal-red-full',
        gridSize: {
          width: 2,
          height: 1
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [75, 85, 160, 168]
        }
      }, {
        name: 'crystal-green-full',
        image: 'harvestables',
        frame: 'crystal-green-full',
        gridSize: {
          width: 2,
          height: 1
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [75, 85, 160, 168]
        }
      }, {
        name: 'crystal-blue-empty',
        image: 'harvestables',
        frame: 'crystal-blue-empty',
        gridSize: {
          width: 2,
          height: 1
        },
        shape: [],
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [80, 100, 160, 126]
        }
      }, {
        name: 'crystal-red-empty',
        image: 'harvestables',
        frame: 'crystal-red-empty',
        gridSize: {
          width: 2,
          height: 1
        },
        shape: [],
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [80, 100, 160, 126]
        }
      }, {
        name: 'crystal-green-empty',
        image: 'harvestables',
        frame: 'crystal-green-empty',
        gridSize: {
          width: 2,
          height: 1
        },
        shape: [],
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [80, 100, 160, 126]
        }
      }, {
        name: 'bush-blueberry-empty',
        image: 'harvestables',
        frame: 'bush-blueberry-empty',
        gridSize: {
          width: 2,
          height: 1
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [105, 87, 204, 172]
        }
      }, {
        name: 'bush-redberry-empty',
        image: 'harvestables',
        frame: 'bush-redberry-empty',
        gridSize: {
          width: 2,
          height: 1
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [105, 87, 204, 172]
        }
      }, {
        name: 'bush-yellowberry-empty',
        image: 'harvestables',
        frame: 'bush-yellowberry-empty',
        gridSize: {
          width: 2,
          height: 1
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [105, 87, 204, 172]
        }
      }, {
        name: 'bush-blueberry-full',
        image: 'harvestables',
        frame: 'bush-blueberry-full',
        gridSize: {
          width: 2,
          height: 1
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [105, 87, 204, 169]
        }
      }, {
        name: 'bush-redberry-full',
        image: 'harvestables',
        frame: 'bush-redberry-full',
        gridSize: {
          width: 2,
          height: 1
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [105, 87, 204, 169]
        }
      }, {
        name: 'bush-yellowberry-full',
        image: 'harvestables',
        frame: 'bush-yellowberry-full',
        gridSize: {
          width: 2,
          height: 1
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [105, 87, 204, 169]
        }
      }, {
        name: 'boulder-rock-empty',
        image: 'harvestables',
        frame: 'boulder-rock-empty',
        gridSize: {
          width: 3,
          height: 2
        },
        shape: [],
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [69, 49, 139, 98]
        }
      }, {
        name: 'boulder-rock-med',
        image: 'harvestables',
        frame: 'boulder-rock-med',
        gridSize: {
          width: 3,
          height: 2
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [107, 88, 214, 176]
        }
      }, {
        name: 'boulder-rock-full',
        image: 'harvestables',
        frame: 'boulder-rock-full',
        gridSize: {
          width: 3,
          height: 2
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [132, 113, 264, 226]
        }
      }, {
        name: 'tree-1',
        image: 'harvestables',
        frame: 'tree-1',
        gridSize: {
          width: 2,
          height: 2
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.polygon,
          points: [180, 0, 349, 238, 350, 353, 273, 529, 90, 529, 12, 353, 12, 238]
        }
      }, {
        name: 'tree-2',
        image: 'harvestables',
        frame: 'tree-2',
        gridSize: {
          width: 2,
          height: 2
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.polygon,
          points: [180, 0, 349, 238, 350, 353, 273, 529, 90, 529, 12, 353, 12, 238]
        }
      }, {
        name: 'tree-3',
        image: 'harvestables',
        frame: 'tree-3',
        gridSize: {
          width: 2,
          height: 2
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [210, 237, 420, 480]
        }
      }, {
        name: 'tree-4',
        image: 'harvestables',
        frame: 'tree-4',
        gridSize: {
          width: 2,
          height: 2
        },
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [210, 237, 420, 480]
        }
      }, {
        name: 'saplingtree-1',
        image: 'harvestables',
        frame: 'saplingtree-1',
        gridSize: {
          width: 2,
          height: 1
        },
        shape: [],
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [35, 53, 70, 106]
        }
      }, {
        name: 'saplingtree-2',
        image: 'harvestables',
        frame: 'saplingtree-2',
        gridSize: {
          width: 2,
          height: 1
        },
        shape: [],
        spriteShape: {
          type: TemplateStore_1.SpriteShapeType.ellipse,
          points: [39, 54, 79, 108]
        }
      }, // TOOL ICONS        
      {
        name: 'axe-icon',
        image: 'tool-icons',
        frame: 'axe-icon'
      }, {
        name: 'pickaxe-icon',
        image: 'tool-icons',
        frame: 'pickaxe-icon'
      }, {
        name: 'hands-icon',
        image: 'tool-icons',
        frame: 'hands-icon'
      }, // RESOURCES ICONS
      {
        name: 'berry-blue-icon',
        image: 'resources-icons',
        frame: 'berry-blue'
      }, {
        name: 'berry-red-icon',
        image: 'resources-icons',
        frame: 'berry-red'
      }, {
        name: 'berry-yellow-icon',
        image: 'resources-icons',
        frame: 'berry-yellow'
      }, {
        name: 'orb-regular-icon',
        image: 'resources-icons',
        frame: 'orb-regular'
      }, {
        name: 'orb-enhanced-icon',
        image: 'resources-icons',
        frame: 'orb-enhanced'
      }, {
        name: 'orb-perfect-icon',
        image: 'resources-icons',
        frame: 'orb-perfect'
      }, {
        name: 'wood-icon',
        image: 'resources-icons',
        frame: 'wood-icon'
      }, {
        name: 'crystal-blue-icon',
        image: 'resources-icons',
        frame: 'crystal-blue-icon'
      }, {
        name: 'crystal-red-icon',
        image: 'resources-icons',
        frame: 'crystal-red-icon'
      }, {
        name: 'crystal-green-icon',
        image: 'resources-icons',
        frame: 'crystal-green-icon'
      }, {
        name: 'stone-icon',
        image: 'resources-icons',
        frame: 'stone-icon'
      }, // RESOURCES PARTICLES
      // @todo Clean up old resources, have a unique resources atlas
      {
        name: 'berry-blue-particle',
        image: 'resources-particles',
        frame: 'berry-blue'
      }, {
        name: 'berry-red-particle',
        image: 'resources-particles',
        frame: 'berry-red'
      }, {
        name: 'berry-yellow-particle',
        image: 'resources-particles',
        frame: 'berry-yellow'
      }, {
        name: 'orb-regular-particle',
        image: 'resources-particles',
        frame: 'orb-regular'
      }, {
        name: 'orb-enhanced-particle',
        image: 'resources-particles',
        frame: 'orb-enhanced'
      }, {
        name: 'orb-perfect-particle',
        image: 'resources-particles',
        frame: 'orb-perfect'
      }, {
        name: 'resource-wood-1',
        image: 'resources-particles',
        frame: 'wood-particle-1'
      }, {
        name: 'resource-wood-2',
        image: 'resources-particles',
        frame: 'wood-particle-2'
      }, {
        name: 'resource-crystal-blue-1',
        image: 'resources-particles',
        frame: 'crystal-blue-1'
      }, {
        name: 'resource-crystal-blue-2',
        image: 'resources-particles',
        frame: 'crystal-blue-2'
      }, {
        name: 'resource-crystal-red-1',
        image: 'resources-particles',
        frame: 'crystal-red-1'
      }, {
        name: 'resource-crystal-red-2',
        image: 'resources-particles',
        frame: 'crystal-red-2'
      }, {
        name: 'resource-crystal-green-1',
        image: 'resources-particles',
        frame: 'crystal-green-1'
      }, {
        name: 'resource-crystal-green-2',
        image: 'resources-particles',
        frame: 'crystal-green-2'
      }, {
        name: 'resource-stone-1',
        image: 'resources-particles',
        frame: 'stone-particle-1'
      }, {
        name: 'resource-stone-2',
        image: 'resources-particles',
        frame: 'stone-particle-2'
      }, // CREATURE EMOTICONS
      {
        name: 'white-bubble-small',
        image: './assets/particles/icon-bubble-hd.png'
      }, {
        name: 'white-bubble-medium',
        image: './assets/particles/icon-bubble-big-hd.png'
      }, {
        name: 'white-bubble-large',
        image: './assets/particles/icon-bubble-big-hd.png'
      }, // POINTER CURSORS
      {
        name: 'default-cursor',
        image: 'mouse-cursors',
        frame: 'default-cursor'
      }, {
        name: 'green-cursor',
        image: 'mouse-cursors',
        frame: 'green-cursor'
      }, {
        name: 'grey-box',
        image: 'hud',
        frame: 'grey-box'
      }, {
        name: 'ftue-arrow',
        image: 'hud',
        frame: 'ftue-arrow'
      }, {
        name: 'iron-ingot',
        image: 'resources-particles',
        frame: 'iron-ingot'
      }, // UI - placeholder
      {
        name: 'placeholder',
        image: './assets/sprites/placeholder.png'
      }, // UI - Common
      {
        name: 'btn-close',
        image: './assets/ui/dragonDex/close-button.png'
      }, {
        name: 'btn-close-red',
        image: './assets/ui/dragonDex/close-button-red.png'
      }, {
        name: 'btn-wide',
        image: './assets/ui/options/pause-button.png'
      }, // UI - Dragon dex
      {
        name: 'dd-entry-bg',
        image: './assets/ui/dragonDex/dragon-base.png'
      }, {
        name: 'dd-hud',
        image: './assets/ui/dragonDex/dragon-dex-button.png'
      }, {
        name: 'dd-title',
        image: './assets/ui/dragonDex/dragon-dex-title.png'
      }, {
        name: 'dd-background',
        image: './assets/ui/dragonDex/dragon-dex-window.png'
      }, {
        name: 'dd-entry-tile',
        image: './assets/ui/dragonDex/name-window.png'
      }, {
        name: 'dd-next',
        image: './assets/ui/dragonDex/next-page.png'
      }, {
        name: 'dd-lock',
        image: './assets/ui/dragonDex/lock.png'
      }, // UI - Unlock Pop Up
      {
        name: 'unlockPopUp-background',
        image: './assets/ui/unlockPopUp/pop-up-window.png'
      }, {
        name: 'unlockPopUp-circle',
        image: './assets/ui/unlockPopUp/pop-up-circle.png'
      }, {
        name: 'unlockPopUp-circle-bg',
        image: './assets/ui/unlockPopUp/pop-up-circle-bg.png'
      }, {
        name: 'unlockPopUp-banner',
        image: './assets/ui/unlockPopUp/pop-up-flag.png'
      }, // UI - Inventroy
      {
        name: 'inventory-bg',
        image: './assets/ui/inventory/item-bar.png'
      }, {
        name: 'inventory-cap-left',
        image: './assets/ui/inventory/item-bar-left.png'
      }, {
        name: 'inventory-cap-right',
        image: './assets/ui/inventory/item-bar-right.png'
      }, {
        name: 'inventory-entry',
        image: './assets/ui/inventory/item-window.png'
      }, // UI - Opions
      {
        name: 'options-button',
        image: './assets/ui/options/setting-button.png'
      }, {
        name: 'options-entry',
        image: './assets/ui/options/pause-button-big.png'
      }]
    }
  };
}

exports.getAssetConfig = getAssetConfig;