//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Storage from '@/components/Storage.js';

const user = Storage.user;

export default {
  name: 'RegisterForm',
  data() {
    return {
      error: null,
      account: null,
      allowLoginRequest: true,
      allowRegisterRequest: true,
      form: {
        email: '',
      },
      labelPosition: 'top',
      rules: {
        email: [
          {type: "email", required: true, message: 'Please enter email', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    onRegister(event) {
      event.preventDefault();
      this.error = null;
      let { email } = this.form;
      email = email.trim().toLowerCase();
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.allowRegisterRequest = false;
          user.register(email).then(() => {
            window.location = '/game.html'
          }).catch((err) => {
            this.error = err.toString();
            this.allowRegisterRequest = true;
          });
        } else {
          return false;
        }
      });
    },
  }
};
