//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Storage from '@/components/Storage.js';

export default {
  name: 'Home',
  data() {
    return {
      account: Storage.user.account,
      inventory: (Storage.user.getGameState()) ? Storage.user.getGameState().inventory : null
    };
  },
  methods: {
    onPlay() {
      window.location = '/game.html'
      /*this.$router.push('/game');*/
    },
    onLogOut() {
      this.$router.push('/logout');
    }
  }
};
