import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/Home';
import Register from '@/components/Register';
import Storage from '@/components/Storage.js';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        authenticate: true
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        authenticate: false
      }
    },
    {
      path: '*',
      name: 'NotFound',
      redirect: '/'
    },
    /*
    {
      path: '/game',
      name: 'Game',
      component: Game,
      meta: {
        authenticate: true
      }
    },
    */
  ],
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.authenticate)) {
    if (!Storage.user.isRegistered()) {
      next({
        path: '/register'
      })
    } else {
      next()
    }
  }
  else if ((to.name === 'Register') && (Storage.user.isRegistered())) {
    next({
      path: '/'
    })
  }
  else {
    next()
  }
})

export default router