import { GameStorage } from './GameStorage';
import { Crypto } from './Crypto';
import * as _ from "lodash"


export class Account {
  constructor(engineVersion, appScriptId) {
    const data = GameStorage.getAccount() || {}

    if (data.registered) {
      this.privKey = data.privKey;
      this.pubKey = data.pubKey;
      this.address = data.address;
      this.email = data.email;
      this.emailPosted = data.emailPosted;
      this._setLastSavedGameState(data.lastSavedGameState)

      if (!this.emailPosted) {
        this._postEmail(this.email, appScriptId)
        .then(() => {
          this.emailPosted = true;
          this._save()
        })
        .catch(() => {
          console.error('Failed to post Email')
        });
      }
    } else {
      this.privKey = Crypto.genKey();
      this.pubKey = Crypto.privateKeyToPublicKey(this.privKey);
      this.address = Crypto.publicKeyToAccAddress(this.pubKey);
      this.email = null;
      this.emailPosted = false;
      this._setLastSavedGameState(null)
    }

    this._registered = data.registered
    this.events = [];
    this.version = engineVersion
    this.debug = false;
  }

  _setLastSavedGameState(state) {
    this.lastSavedGameState = _.cloneDeep(state)
  }

  async _postEmail(email, appScriptId) {
    const encodedEmail = encodeURIComponent(email)
    const url = `https://script.google.com/macros/s/${appScriptId}/exec?email=${encodedEmail}`
    const response = await fetch(url, { method: 'POST', mode: 'no-cors' })

    if (!response.ok) {
      throw 'Failed to post Email'
    }      
  }

  getLastSavedGameState() {
    return (this.lastSavedGameState == null) ? null : _.cloneDeep(this.lastSavedGameState)
  }

  isRegistered() {
    return this._registered
  }

  _save() {
    const data = {
      registered: this._registered,
      privKey: this.privKey,
      pubKey: this.pubKey,
      address: this.address,
      email: this.email,
      emailPosted: this.emailPosted,
      lastSavedGameState: this.lastSavedGameState
    };
    GameStorage.saveAccount(data);
  }

  markAsRegistered(email) {
    this._registered = true
    this.email = email
    ga('set', 'userId', this.address)
    this._save()
  }

  async saveReviewedGameState(newGameState, processedEventsCount) {
    try {
      this._setLastSavedGameState(newGameState)
      this._removeEventsFromHistory(processedEventsCount);
      this._save();
      return this;
    } catch (error) {
      throw new Error(error);
    }
  }

  _removeEventsFromHistory(eventsCount) {
    this.events = this.events.slice(eventsCount)
  }

  saveEventsToLocalStorage(events) {
    this.events = this.events.concat(events);
  }
}
