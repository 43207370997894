"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Color;

(function (Color) {
  Color[Color["darkgrey"] = 4210752] = "darkgrey";
  Color[Color["green"] = 4445273] = "green";
  Color[Color["red"] = 16724779] = "red";
  Color[Color["blue"] = 28671] = "blue";
  Color[Color["grey"] = 8421504] = "grey";
  Color[Color["lightgrey"] = 12632256] = "lightgrey";
  Color[Color["white"] = 16777215] = "white";
  Color[Color["redTint"] = 16755370] = "redTint";
  Color[Color["greenTint"] = 11206570] = "greenTint";
  Color[Color["blueTint"] = 11184895] = "blueTint";
  Color[Color["bone"] = 16710110] = "bone";
  Color[Color["paleyellow"] = 16774558] = "paleyellow";
  Color[Color["winered"] = 3869708] = "winered";
  Color[Color["darkbrown"] = 1705728] = "darkbrown";
})(Color = exports.Color || (exports.Color = {}));

function colorString(color) {
  return '#' + color.toString(16);
}

exports.colorString = colorString;