var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-container register-page" }, [
    _c("div", { staticClass: "logo-container" }),
    _vm._v(" "),
    _c("div", { staticClass: "input-container" }, [
      _c("div", { staticClass: "logo-title" }, [
        _vm._v("\n      Ready to play?\n      ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "separator" }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "login-form" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
                "label-position": _vm.labelPosition
              }
            },
            [
              _vm.error
                ? _c("el-alert", {
                    attrs: { title: _vm.error, type: "error", "show-icon": "" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Email", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "email",
                      name: "email",
                      placeholder: "Enter your email"
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { "label-width": "0px" } }, [
                _c(
                  "button",
                  {
                    staticClass: "button-4 active-button w-button login-button",
                    attrs: { disabled: !_vm.allowLoginRequest },
                    on: { click: _vm.onRegister }
                  },
                  [_vm._v("\n            Play Now!\n          ")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }