// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import RegisterForm from './components/RegisterForm';
import Storage from '@/components/Storage.js';
import agentConfig from 'agent-config'

ga('create', agentConfig.gaTrackingId, 'auto')
ga('set', 'userId', Storage.user.getAccountAddress())
ga('send', 'pageview')

Vue.component('register-form', RegisterForm);
Vue.use(ElementUI);
Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  data: {
    account: null
  }
});
