const CryptoJS = require('crypto-js');
const EC = require('elliptic').ec;
const bech32 = require('bech32');

const secp256k1 = new EC('secp256k1');

const Crypto = {
  genKey() {
    const key = secp256k1.genKeyPair();
    return key.getPrivate('hex');
  },

  publicKeyToAddress(pubKey) {
    const keyBytes = CryptoJS.enc.Hex.parse(pubKey);
    const address = CryptoJS.RIPEMD160(CryptoJS.SHA256(keyBytes)).toString();
    return address.toUpperCase();
  },

  publicKeyToAccAddress(pubKey) {
    const keyBytes = CryptoJS.enc.Hex.parse(pubKey);
    const addressWords = CryptoJS.RIPEMD160(CryptoJS.SHA256(keyBytes));
    const addressBytes = CryptoJS.enc.u8array.stringify(addressWords);
    const converted = bech32.toWords(addressBytes);
    return bech32.encode('cosmosaccaddr', converted);
  },

  privateKeyToPublicKey(privKey) {
    const keyPair = secp256k1.keyFromPrivate(privKey);
    const yPrefix = (keyPair.getPublic().getY().isEven() ? '02' : '03');
    const pubKey = yPrefix + keyPair.getPublic().getX().toString(16);
    return pubKey.toUpperCase();
  },

  privateKeyToAddress(privKey) {
    const pubKey = Crypto.privateKeyToPublicKey(privKey);
    const address = Crypto.publicKeyToAddress(pubKey);
    return address.toUpperCase();
  },

  privateKeyToAccAddress(privKey) {
    const pubKey = Crypto.privateKeyToPublicKey(privKey);
    return Crypto.publicKeyToAccAddress(pubKey);
  },

  signData(data, privKey) {
    const signature = secp256k1.sign(Crypto.sha256(data), privKey, { canonical: true });
    return CryptoJS.enc.u8array.parse(signature.toDER()).toString(CryptoJS.enc.Hex);
  },

  decompressPublicKey(pubKeyCompressed) {
    const pubKeyX = pubKeyCompressed.substring(2, 66);
    const pubKeyYEven = pubKeyCompressed.substring(0, 2) === '02';
    const pubKeyPoint = secp256k1.curve.pointFromX(pubKeyX, (pubKeyYEven ? 0 : 1));
    return pubKeyPoint;
  },

  verifySignature(data, publicKey, signature) {
    const pubKeyPoint = Crypto.decompressPublicKey(publicKey);
    const keyPair = secp256k1.keyPair({ pub: pubKeyPoint });
    const valid = keyPair.verify(Crypto.sha256(data), signature);
    return valid;
  },

  sha256(data) {
    return CryptoJS.SHA256(data).toString();
  },

  b64ToHex(b64) {
    const bytes = CryptoJS.enc.Base64.parse(b64);
    return bytes.toString(CryptoJS.enc.Hex);
  },

  hexToB64(hex) {
    const bytes = CryptoJS.enc.Hex.parse(hex);
    return bytes.toString(CryptoJS.enc.Base64);
  },

  sha1(data) {
    return CryptoJS.SHA1(data).toString();
  },

  jsonToSha1(json) {
    const data = (json == null) ? '' : JSON.stringify(json);
    return CryptoJS.SHA1(data).toString();
  },
};

CryptoJS.enc.u8array = {
  stringify(wordArray) {
    const { words } = wordArray;
    const { sigBytes } = wordArray;
    const u8 = new Uint8Array(sigBytes);
    for (let i = 0; i < sigBytes; i += 1) {
      const byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
      u8[i] = byte;
    }
    return u8;
  },
  parse(u8arr) {
    const len = u8arr.length;
    const words = [];
    for (let i = 0; i < len; i += 1) {
      words[i >>> 2] |= (u8arr[i] & 0xff) << (24 - (i % 4) * 8);
    }
    return CryptoJS.lib.WordArray.create(words, len);
  },
};

export { Crypto };
