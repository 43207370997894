import { StateManager } from '../../src/StateManager';

export default {

  env: 'production',
  gaTrackingId: 'UA-126443701-1',
  appScriptId: 'AKfycbxucx67K5mA7DOEmudb1eb10xT0NE2s5_T8hcf93qetN0ciOeQ',

  agent: {
    updateReviewersListInterval: 30000,

    authorized_reviewers: [
      'https://nxgj85jnak.execute-api.ap-northeast-2.amazonaws.com/latest',
    ],
  },

  webRTC: {
    signalling: {
      label: 'review',
      key: 'blockmon',
      host: 'signaling.auranetwork.io',
      port: 80,
    },
    iceServers: [
      /*stun.l.google.com:19302*/
      { urls: 'stun:52.78.202.182:25122' },
      { urls: 'turn:52.78.202.182:5766', credential: '72C94178-0590-4A05-AB7D-F2EAFBC63225', username: 'user' },
    ],
  },
  ledger: {
    pingInterval: 60000,
    chain_id: 'testnet',
    version: StateManager.ENGINE_VERSION,
    nodes: [
      {
        name: 'hd-game-ledger.auranetwork.io',
        url: 'http://hd-game-ledger.auranetwork.io:80',
        restRpcUrl: 'http://hd-game-ledger.auranetwork.io:20657',
      },
    ],
  },
};
