var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "home-container" },
    [
      _c(
        "el-main",
        { staticClass: "home-main-block" },
        [
          _c(
            "el-card",
            { staticClass: "box-card home-card", attrs: { align: "left" } },
            [
              _c("div", { staticClass: "logo-container" }),
              _vm._v(" "),
              _c("el-row", [
                _c("h3", { staticClass: "profile-title" }, [
                  _vm._v("Your Profile")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "separator" }),
                _vm._v(" "),
                _c("div", { staticClass: "card-item" }, [
                  _c("b", { staticClass: "name" }, [_vm._v("Email")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "key" }, [
                    _vm._v(_vm._s(_vm.account.email))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-item" }, [
                  _c("b", { staticClass: "name" }, [_vm._v("Address")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "key" }, [
                    _vm._v(_vm._s(_vm.account.address))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-item" }, [
                  _c("b", { staticClass: "name" }, [_vm._v("Public Key")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "key" }, [
                    _vm._v(_vm._s(_vm.account.pubKey))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "separator buttons-top" }),
                _vm._v(" "),
                _c("div", { staticClass: "buttons-container" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button-4 active-button w-button",
                      attrs: { type: "success" },
                      on: { click: _vm.onPlay }
                    },
                    [_vm._v("Play Now!")]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }