const STORAGE_KEY = "HDGAMEACCOUNTDATA"

const GameStorage = {
  get(key) {
    const value = localStorage[key];
    return (!value) ? null : JSON.parse(value);
  },
  set(key, value) {
    localStorage[key] = JSON.stringify(value);
  },
  drop() {
    delete localStorage[STORAGE_KEY]
  },
  getAccount() {
    return GameStorage.get(STORAGE_KEY);
  },
  saveAccount(account) {
    GameStorage.set(STORAGE_KEY, account);
  }
};
export {
  GameStorage
};
