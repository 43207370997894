var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-contaienr" }, [
    _c("div", { staticClass: "login-container" }, [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("register-form"),
          _vm._v(" "),
          _c("div", { staticClass: "hero-container" })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _c("div", { staticClass: "login-container" }, [
      _c("div", { staticClass: "content" }, [_c("register-form")], 1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "story-container" }, [
      _c("div", { staticClass: "story-content" }, [
        _c("h1", [_vm._v("Hello there!")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        Welcome to the world of dragons, a peaceful land full of cool and brave HashDragons.\n        You as the dragon trainer have a duty in this friendly realm. Your tasks are to\n        collect useful resources to spawn and take care of many different dragons! The\n        objects and resources that you will be collecting are wood, berries, gems and more.\n      "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "resources" }, [
          _c("img", {
            staticClass: "resource-icon",
            attrs: { src: "/images/icons/wood.png" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "resource-icon",
            attrs: { src: "/images/icons/berry-red.png" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "resource-icon",
            attrs: { src: "/images/icons/berry-yellow.png" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "resource-icon",
            attrs: { src: "/images/icons/berry-blue.png" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "resource-icon",
            attrs: { src: "/images/icons/gem-red.png" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "resource-icon",
            attrs: { src: "/images/icons/gem-green.png" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "resource-icon",
            attrs: { src: "/images/icons/gem-blue.png" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "resource-icon",
            attrs: { src: "/images/icons/stone.png" }
          })
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        Now,where was I, ahhh yes, the Hashdragons! Who could forget about them...\n        They are a special and magnificent breed of dragons that love companionship, and\n        once you have trained them, you can go off and explore the magnificent kingdoms that\n        are hidden all around DragonVille...\n      "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "dragons" }, [
          _c("span", { staticClass: "dragon-silhouette" }, [
            _c("img", { attrs: { src: "/images/silhouettes/dragon-x.png" } })
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "dragon-silhouette" }, [
            _c("img", { attrs: { src: "/images/silhouettes/dragon-y.png" } })
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "dragon-silhouette" }, [
            _c("img", { attrs: { src: "/images/silhouettes/dragon-z.png" } })
          ])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        You can keep track of your dragons in the Dragon Dex! Train all of your dragons to\n        become the best dragon trainer in DragonVille and beyond. Ok I will let you be now,\n        back to my wizarding! Oh don’t let me forget “Happy training!!!”\n      "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "story-container" }, [
      _c("div", { staticClass: "story-content" }, [
        _c("h1", [_vm._v("Explore the world")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        Start your dragon collecting adventure by exploring the wild world.\n        Discover new corners as you learn to navigate the world efficiently.\n      "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "screenshot" }, [
          _c("img", { attrs: { src: "/images/screenshots/ss1.jpg" } })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "story-container" }, [
      _c("div", { staticClass: "story-content" }, [
        _c("h1", [_vm._v("Collect Resources")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        Collecting the right resources will make your inventory stronger.\n        The world has amazing resources waiting to be collected.\n      "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "screenshot" }, [
          _c("img", { attrs: { src: "/images/screenshots/ss2.jpg" } })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "story-container" }, [
      _c("div", { staticClass: "story-content" }, [
        _c("h1", [_vm._v("Train Dragons")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        Use your inventory to train the dragon. Once trained, dragons will give you special\n        orbs.\n      "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "screenshot" }, [
          _c("img", { attrs: { src: "/images/screenshots/ss3.jpg" } })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }